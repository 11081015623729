import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Call, callSchema } from "../domain/call";

export type CreateCallData = Partial<Call>;

export const createCall = async ({ authToken, data }: { authToken: string; data: CreateCallData }) => {
    const response = await makeRequest({
        method: "post",
        path: "/calls",
        authToken,
        data,
    });

    const call = parseSchema(callSchema, response.data);

    return call;
};
