import { useEffect, useState } from "react";
import ModalDialog from "../../layout/modal-dialog";
import TextAreaNeoGenControlled from "../../layout/text-area-controlled";
import Select from "react-tailwindcss-select";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useMutation, useQuery } from "@tanstack/react-query";
import { StatusType, getCallStatuses } from "../actions/get-call-statuses";
import { AgentType, getAgents } from "../../calendar-events/actions/get-agents";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { AddCalendarEventSection } from "../../calendar-events/components/add-calendar-event-section";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { CreateCallData, createCall } from "../actions/create-call";
import ButtonNeoGen from "../../layout/button-neogen";
import { getCallTags } from "../../calendar-events/actions/get-call-tags";

export type callStateTypes = "calling" | "onHold" | "inCall" | "transferCall" | "addEngagement" | "endCall";

export const NewCallModal = ({ onClose, user }: { onClose: () => void; user: ClearERCUser }) => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [callDuration, setCallDuration] = useState(0);
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const tagsQuery = useQuery(["company-tags"], async () => {
        const tags = await getCallTags({ authToken });
        return tags;
    });
    const tags = tagsQuery.data || [];

    const [callState, setCallState] = useState<callStateTypes>("calling");
    const name = `${user.firstName} ${user.lastName} - ${user.name}`;
    const [notes, setNotes] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);

    const handleTagChange = (value: any) => {
        setSelectedTags(value);
    };

    useEffect(() => {
        startCall();
        return endCall;
    }, []);

    let callStart: Date;

    function startCall() {
        callStart = new Date();
        setInterval(() => {
            const now = new Date();
            const duration = now.getTime() - callStart.getTime();
            setCallDuration(duration);
        }, 1); // update every millisecond
    }

    function endCall() {
        clearInterval(undefined);
    }

    const createCallMutation = useMutation({
        mutationFn: async (data: CreateCallData) => {
            const call = await createCall({ authToken, data: data });
            return call;
        },
    });

    const handleSubmit = async () => {
        await createCallMutation.mutateAsync({
            date: new Date(),
            duration: callDuration,
            dialerNumber: loggedInUser.phone,
            dialerUserId: user.id,
            receiverUserId: loggedInUser.id,
            receiverNumber: user.phone,
            status: selectedStatus,
            notes: notes,
        });
        onClose();
    };

    useEffect(() => {
        setTimeout(async () => {
            setCallState("inCall");
        }, 2000);
    }, []);

    const statusQuery = useQuery(["call-status"], async () => {
        const statuses = await getCallStatuses({ authToken });
        return statuses;
    });
    const callStatusButtons: StatusType[] = statusQuery.data || [];

    const agentsQuery = useQuery(["agents"], async () => {
        const agents = await getAgents({ authToken });
        return agents;
    });
    const agents: AgentType[] = agentsQuery.data || [];

    return (
        <ModalDialog
            size={"md"}
            show={true}
            close={onClose}
            title={callState === "calling" ? `Calling ${name}` : `In call with ${name} `}
            showCancel={false}
            showOk={false}
        >
            {callState === "calling" ? (
                <div className="text-center mt-5">
                    <ButtonNeoGen text="End call" onClick={() => setCallState("endCall")} />
                </div>
            ) : callState === "onHold" ? (
                <div className="flex flex-col items-stretch">
                    <p className="text-center my-8">Call is on Hold</p>
                    <div className="flex gap-2 justify-end w-full">
                        <ButtonNeoGen text="End call" onClick={() => setCallState("endCall")} className="!bg-red-500" />
                        <ButtonNeoGen text="Resume call" onClick={() => setCallState("inCall")} />
                    </div>
                </div>
            ) : callState === "inCall" ? (
                <div className="flex flex-col gap-2 justify-between items-start">
                    <div className="w-full">
                        <TextAreaNeoGenControlled label="Add notes" name="Notes" value={notes} setValue={setNotes} />
                        <div className="my-5">
                            <p className="my-3 text-sm">Tags</p>
                            <Select
                                isSearchable={true}
                                isMultiple={true}
                                options={(tags || []).map((tag) => ({ value: tag.tag, label: tag.tag }))}
                                onChange={handleTagChange}
                                value={selectedTags}
                                primaryColor="#6610f2"
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 justify-end w-full">
                        <ButtonNeoGen text="Add calendar invite" onClick={() => setCallState("addEngagement")} />
                        <ButtonNeoGen text="Put on hold" onClick={() => setCallState("onHold")} />
                        <ButtonNeoGen text="Transfer call" onClick={() => setCallState("transferCall")} />
                        <ButtonNeoGen text="End call" onClick={() => setCallState("endCall")} className="!bg-red-500" />
                    </div>
                </div>
            ) : callState === "endCall" ? (
                <div>
                    <p className="my-3 text-sm">Call status</p>
                    <div className="flex gap-2 flex-wrap mb-5">
                        {callStatusButtons.map((button) => (
                            <ButtonNeoGen
                                type={selectedStatus === button.status ? "primary" : "outline-primary"}
                                text={button.status}
                                key={button.id}
                                onClick={() =>
                                    selectedStatus === button.status
                                        ? setSelectedStatus(undefined)
                                        : setSelectedStatus(button.status)
                                }
                            />
                        ))}
                    </div>
                    <div className="flex gap-2 justify-end w-full">
                        <ButtonNeoGen text="Save" onClick={handleSubmit} />
                    </div>
                </div>
            ) : callState === "transferCall" ? (
                <div>
                    <p className="mt-5">Select agent to transfer call to:</p>
                    <div className="flex gap-2 flex-wrap my-5">
                        {agents.map((agent) => (
                            <ButtonNeoGen key={agent.name} type="outline" onClick={() => setCallState("endCall")}>
                                <div className="flex gap-2 items-center">
                                    <div
                                        className={`h-2 w-2 rounded-full ${
                                            agent.status === "available"
                                                ? "bg-green-500"
                                                : agent.status === "offline"
                                                ? "bg-red-600"
                                                : "bg-orange-500"
                                        }`}
                                    />
                                    {agent.name}
                                </div>
                            </ButtonNeoGen>
                        ))}
                    </div>
                    <div className="flex gap-2 justify-end w-full">
                        <ButtonNeoGen text="Cancel" onClick={() => setCallState("inCall")} />
                    </div>
                </div>
            ) : (
                <AddCalendarEventSection onClose={() => setCallState("inCall")} user={user} />
            )}
        </ModalDialog>
    );
};
