export const Checkbox = ({
    label,
    isChecked,
    onChange,
    disabled,
}: {
    label?: string;
    isChecked?: boolean;
    onChange: (isChecked?: boolean) => any;
    disabled?: boolean;
}) => {
    return (
        <div className="relative flex items-center ">
            <div className="flex items-center h-5">
                <input
                    aria-describedby="comments-description"
                    disabled={disabled}
                    type="checkbox"
                    checked={isChecked}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 dark:text-indigo-300 dark:border-gray-900 rounded checkboxes"
                    onChange={(e) => {
                        const isChecked = e.target.checked;
                        onChange(isChecked);
                    }}
                />
            </div>
        </div>
    );
};
