import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { callTagSchema } from "../domain/call-tags";

export const getCallTags = async ({ authToken }: { authToken: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/call-tags`,
        authToken,
    });

    const dtos = parseSchema(z.array(callTagSchema), response.data);

    const callTags = parseSchema(
        z.array(callTagSchema),
        dtos.map((dto) => ({ ...dto })),
    );

    return callTags;
};
