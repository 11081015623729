import { HomeIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export default function BreadCrumbs(props: BreadCrumbProps) {
    return (
        <nav className={"flex ml-0 cursor-pointer pb-0 " + (props.addMb ? "mb-5" : "my-0")} aria-label="Breadcrumb">
            <ol
                role="list"
                className="flex space-x-4 rounded-lg bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white   dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner  px-6 shadow"
            >
                {!props.hideHome && (
                    <li className="flex">
                        <div className="flex items-center">
                            <Link
                                to="/dashboard"
                                className="text-slate-400 hover:text-indigo-500 dark:text-slate-300 dark:hover:text-indigo-500 "
                            >
                                <i className="fal fa-home mr-2  hover:animate-pulse transition-all duration-1000 delay-0 ease-out"></i>
                                <span className="sr-only">Home</span>
                            </Link>
                        </div>
                    </li>
                )}
                {props.pages.map((page, index) => (
                    <li key={page.name} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="h-full w-6 flex-shrink-0 text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            {page.onClick ? (
                                <button
                                    onClick={() => page.onClick && page.onClick()}
                                    className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                    aria-current={page.current ? "page" : undefined}
                                >
                                    {page.name}
                                </button>
                            ) : (
                                <Link
                                    to={page.href || ""}
                                    className="ml-4 text-sm font-medium text-gray-400 hover:text-gray-700"
                                    aria-current={page.current ? "page" : undefined}
                                >
                                    {page.name}
                                </Link>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}

export type Page = {
    name: string;
    href?: string;
    onClick?: () => void;
    current?: boolean;
};

type BreadCrumbProps = {
    pages: Page[];
    addMb?: boolean;
    hideHome?: boolean;
};
