import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import roleGroupEntryService from "../../../../services/role-group-entry.service";
import roleGroupService from "../../../../services/role-group.service";
import roleService from "../../../../services/role.service";
import UserContext from "../../../../services/user-context";
import { RoleGroupEntry } from "../../../../typings/api/role-group-entry";
import ButtonNeoGen from "../../../../layout/button-neogen";
import CheckBoxNeoGenControlled from "../../../../layout/checkbox-controlled";
import InputControlled from "../../../../layout/input-controlled";
import ModalDialog from "../../../../layout/modal-dialog";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import Loader2 from "../../../utilities/Loader2";

export default function AddEditRoleGroup(props: AddEditRoleGroupProps) {
    const [groupName, setGroupName] = useState("");
    const [roles, setRoles] = useState<number[]>([]);
    const [groupDescription, setGroupDescription] = useState("");
    const [user] = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const queryCache = useQueryClient();
    const rolesQuery = useQuery(["roles"], async () => {
        const response = await roleService.getAll();
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        if (props.show) {
            if (props.isEdit && props.group && props.roles) {
                setGroupName(props.group.name ?? "?");
                setGroupDescription(props.group.description ?? "");
                setRoles(props.roles);
                setSaving(false);
            } else {
                setRoles([]);
                setSaving(false);
                setGroupName("");
                setGroupDescription("");
            }
        }
    }, [props.group, props.isEdit, props.roles, props.show]);
    async function save() {
        setSaving(true);

        if (props.isEdit) {
            const response = await roleGroupService.update(props.group.id, {
                name: groupName,
                description: groupDescription,
            });
            if (response) {
                const where = {
                    roleGroupId: props.group.id,
                };
                roleGroupEntryService.deleteWhere(roleGroupEntryService.endpoint, where);
                for (const role of roles) {
                    const newRoleGroupEntry: RoleGroupEntry = {
                        roleGroupId: props.group.id,
                        roleId: role,
                    };
                    const roleGroupEntryResponse = await roleGroupEntryService.create(newRoleGroupEntry);
                }
                Swal.fire({
                    title: "Success",
                    text: "Role Group updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                queryCache.invalidateQueries(["roleGroupEntries"]);
                props.close();
            }
            setSaving(false);
        } else {
            const newRoleGroup = {
                name: groupName,
                description: groupDescription,
                owner: user.company,
                isPublic: 0,
            };
            const response = await roleGroupService.create(newRoleGroup);
            if (response && response.data) {
                const newId = response.data.id;
                console.error({ newId, roles });
                for (const role of roles) {
                    const newRoleGroupEntry: RoleGroupEntry = {
                        roleGroupId: newId,
                        roleId: role,
                    };
                    const roleGroupEntryResponse = await roleGroupEntryService.create(newRoleGroupEntry);
                }
                queryCache.invalidateQueries(["roleGroupEntries"]);
                props.close();
                Swal.fire({
                    title: "Success",
                    text: "Role Group created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Error creating role group",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            setSaving(false);
        }
    }
    function toggleAllCheckBoxes(className: string) {
        const checkboxes = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLInputElement>;
        let existingRoles = _.cloneDeep(roles);
        for (const checkbox of checkboxes) {
            // const wasChecked = checkbox.checked;
            checkbox.checked = !checkbox.checked;
            if (checkbox.checked) {
                existingRoles = [...existingRoles, Number(checkbox.id)];
            } else {
                existingRoles = existingRoles.filter((id) => id !== Number(checkbox.id));
            }
        }
        setRoles(existingRoles);
    }

    return (
        <ModalDialog
            size="lg"
            close={props.close}
            show={props.show}
            title={props.isEdit ? "Edit Role Group" : "Add Role Group"}
            okAction={() => save()}
        >
            {saving && <div className="text-center mt-10 text-xl text-blue-500">Please Wait, Saving...</div>}
            {rolesQuery.isLoading || saving ? (
                <Loader2 />
            ) : (
                <>
                    {/* <PrintPre>
                        {roles}
                    </PrintPre> */}
                    <InputControlled
                        label={"Group Name"}
                        value={groupName}
                        setValue={function (e: string): void {
                            setGroupName(e);
                        }}
                    />
                    <TextAreaNeoGenControlled
                        label={"Group Description"}
                        value={groupDescription}
                        setValue={function (e: string): void {
                            setGroupDescription(e);
                        }}
                    />
                    {/* <PrintPre>
                        {rolesQuery.data}
                    </PrintPre> */}

                    <ButtonNeoGen onClick={() => toggleAllCheckBoxes("checkboxes")} text={"Toggle All"} />
                    <div className="relative flex  flex-col justify-center ">
                        <div
                            className={
                                "columns-5 2xl:columns-10 " +
                                "gap-2 [column-fill:_balance] " +
                                "box-border mx-auto before:box-inherit after:box-inherit"
                            }
                        >
                            {rolesQuery.data?.map((role) => {
                                return (
                                    <div className="break-inside-avoid " key={role.id}>
                                        <CheckBoxNeoGenControlled
                                            label={role.name ?? ""}
                                            name={role.name ?? ""}
                                            id={role.id?.toString() ?? ""}
                                            value={roles.includes(role.id ?? -1)}
                                            setValue={(e) => {
                                                if (e.target.checked) {
                                                    setRoles([...roles, role.id ?? -1]);
                                                } else {
                                                    setRoles(roles.filter((id) => id !== role.id));
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </ModalDialog>
    );
}
type AddEditRoleGroupProps = {
    show: boolean;
    close: () => void;
    isEdit: boolean;
    group?: any;
    roles?: number[];
};
