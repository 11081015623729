import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userCompanySchema } from "../domain/user-company";

export const deleteUserCompany = async ({ authToken, id }: { authToken: string; id: number }) => {
    const response = await makeRequest({
        method: "delete",
        path: `/user-companies/${id}`,
        authToken,
    });
};
