import { useQuery } from "@tanstack/react-query";
import PageDescription from "../../../layout/page-description";
import externalSyncService from "../../../services/external-sync.service";
import PrintPre from "../../../layout/print-pre";
import Loader3 from "../../utilities/Loader3";
import { Key } from "react";
import { formatPhonenumber } from "../../utilities/formatters";
import TableNeogen from "../../../layout/table-neogen";
import usersService from "../../../services/users.service";
import userService from "../../../services/user.service";
// const externalSyncService =

export default function CompareDealsMonday() {
    const externalSyncServiceQuery = useQuery(["deals"], async () => {
        const result = await externalSyncService.getDeals();
        if (result) {
            return result;
        }
        return [];
    });

    const usersQuery = useQuery(["users"], async () => {
        const result = await userService.getAll();
        if (result) {
            return result.data;
        }
        return [];
    });
    const affiliatesQuery = useQuery(["users", "affiliates", "magicLink"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });

    return (
        <>
            <PageDescription
                title="Compare Deals Monday"
                description="Lets you compare the deal statuses in Monday with the deal statuses in the system."
            />

            {usersQuery.isLoading || !externalSyncServiceQuery.data || affiliatesQuery.isLoading ? (
                <>
                    <Loader3 />
                    {/* <PrintPre>{externalSyncServiceQuery.data}</PrintPre> */}
                </>
            ) : (
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        {/* <PrintPre>{affiliatesQuery}</PrintPre> */}
                        {/* <PrintPre>{Object.values(externalSyncServiceQuery.data.boards[0].items)}</PrintPre> */}
                        {/* <PrintPre>{Object.values(externalSyncServiceQuery.data.boards[0].items)}</PrintPre> */}

                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <TableNeogen
                                headers={[
                                    "Name",
                                    "Stage",
                                    "Phone",
                                    "Last Contacted",
                                    "Monday Affiliate",
                                    "ClearERC User",
                                ]}
                                formatters={[
                                    {
                                        field: "Phone",
                                        type: "Phone",
                                    },
                                    {
                                        field: "LastContacted",
                                        type: "Date",
                                    },
                                    {
                                        field: "AffiliateId",
                                        type: "SelectSearch",
                                        options: affiliatesQuery.data?.map((e: any) => {
                                            return {
                                                id: e.id,
                                                name: e.name,
                                            };
                                        }),
                                    },
                                    {
                                        field: "PlatformLink",
                                        type: "SelectSearch",
                                        options: usersQuery.data
                                            ?.map((e: any) => {
                                                console.error(e);
                                                return {
                                                    id: e.id,
                                                    name: e.name,
                                                };
                                            })
                                            .sort((a: any, b: any) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (a.name > b.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            }),
                                    },
                                ]}
                                entries={externalSyncServiceQuery.data.boards[0]?.items
                                    ?.map(
                                        (
                                            e: { boards: any[] | undefined; data: any[] | undefined },
                                            idx: Key | null | undefined,
                                        ) => {
                                            return {
                                                Name: externalSyncService.getNameFromItem(e) ?? "No Name",
                                                Stage: externalSyncService.getStageFromItem(e) ?? "No Stage",
                                                Phone: externalSyncService.getPhoneFromItem(e) ?? "No Phone",
                                                LastContacted:
                                                    externalSyncService.getLastContactedFromItem(e) ??
                                                    "No Last Contacted",
                                                AffiliateId:
                                                    externalSyncService.getAffiliateFromItem(e) ?? "No Affiliate",
                                                PlatformLink: externalSyncService.getLinkFromItem(e) ?? "No Link",
                                            };
                                        },
                                    )
                                    .sort((a: { Stage: number }, b: { Stage: number }) => {
                                        if (a.Stage < b.Stage) {
                                            return -1;
                                        }
                                        if (a.Stage > b.Stage) {
                                            return 1;
                                        }
                                        return 0;
                                    })}
                            />
                            {/*                                 
                                            ]}
                                        />

                                        
                                {/* ); */}
                            {/* }, */}
                            {/* )} */}
                        </div>
                        {
                            // <PrintPre>{externalSyncServiceQuery.data}</PrintPre>
                        }
                    </div>
                </div>
            )}
        </>
    );
}
