import ButtonNeoGen from "../../../layout/button-neogen";

export function AddingCompanyButtons(props: AddingCompanyButtonsProps) {
    return (
        <div className="col-span-6 px-6 pb-6 grid grid-cols-3 gap-5">
            <ButtonNeoGen
                block
                onClick={async () => {
                    props.setAddingCompany(false);
                    // props.previousTab();
                }}
                size="lg"
                type="danger"
                text="Back to Company List"
                icon="fas fa-chevron-left"
                iconAlign="left"
            />
            <ButtonNeoGen
                block
                onClick={async () => {
                    // props.previousTab();
                }}
                size="lg"
                type="info"
                text="Save and Add Another"
                icon="fas fa-plus"
                iconAlign="left"
            />
            <ButtonNeoGen
                block
                onClick={async () => {
                    props.nextTab();
                }}
                size="lg"
                type="primary"
                text="Done Adding Companies"
                icon="fas fa-chevron-right"
                iconAlign="right"
            />
        </div>
    );
}

type AddingCompanyButtonsProps = {
    currentStep: number;
    steps: any[];
    previousTab: () => void;
    nextTab: () => void;
    saveAction: () => void;
    setAddingCompany: (addingCompany: boolean) => void;
};
