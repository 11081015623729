import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Call, callSchema } from "../domain/call";

export const updateCall = async ({ authToken, id, data }: { authToken: string; id: string; data: Partial<Call> }) => {
    const response = await makeRequest({
        method: "patch",
        path: `/calls/${id}`,
        authToken,
        data,
    });

    const call = parseSchema(callSchema, response.data);

    return call;
};
