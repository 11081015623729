import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import ButtonNeoGen from "../../../layout/button-neogen";
import TableNeogen from "../../../layout/table-neogen";
import interactionsService from "../../../services/interactions.service";
import usersService from "../../../services/users.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import NewMessage from "../../messages/modals/new-message";
import ShowMessage from "../../messages/modals/show-message";
import CustomerCard from "../customer-card";
import CallsList from "./calls-list";
import NotesList from "./notes-list";

export default function PhoneCard({ user }: { user?: ClearERCUser }) {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const [page, setPage] = useState("basic");
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    return (
        <>
            <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Phone Calls</h1>
            {/* <form className=" mt-6 space-y-8 divide-y"> */}
            {/* <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6"> */}
            {/* <div className="sm:col-span-6"> */}
            <div className="mt-3">
                <ButtonNeoGen text="Add Phone Call" onClick={() => setShow(true)} />
                {/* </div> */}
                {/* </div> */}
                <div className="mt-5 w-full overflow-hidden">
                    <CallsList />
                </div>
            </div>

            <NewMessage show={show} setShow={setShow} type={2} to={id} />
            <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} />
        </>
    );
}
