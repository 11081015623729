import { ClientStatuses } from "../typings/api/client-statuses";
import APIService from "./api.service";

class ClientStatusesService extends APIService<ClientStatuses> {
    constructor() {
        super("client-statuses");
    }
}

export default new ClientStatusesService();
