import { useState } from "react";
import ButtonNeoGen from "../../../layout/button-neogen";
import InputNeoGen from "../../../layout/input-neogen";
// import SelectNeoGen from "../../layout/select-neogen";

export function AddCompanySection(props: AddCompanySectionProps) {
    // const [companies, setCompanies] = useState([]);
    // const [addingCompany, setAddingCompany] = useState(false);
    return (
        <div className="pt-6 px-6  grid grid-cols-2 gap-x-5">
            {/* <PrintPre>
               {usersQuery.data}
            </PrintPre> */}
            {props.companies.length === 0 && !props.addingCompany && (
                <div className="flex justify-center col-span-2 p-24">
                    <div className="flex flex-col items-center">
                        <div className="text-center">
                            <div className="text-2xl font-medium">No companies found</div>
                            <div className="text-lg">Please add a company to continue</div>
                            <ButtonNeoGen
                                className="mt-10"
                                onClick={async () => {
                                    props.setAddingCompany(true);
                                }}
                                size="lg"
                                type="info"
                                text="Add Company"
                                icon="fas fa-plus"
                                iconAlign="left"
                            />
                        </div>
                    </div>
                </div>
            )}
            {props.companies.length > 0 && <></>}
            {props.addingCompany && (
                <>
                    <InputNeoGen label={"Company Name"} register={props.register} name={"name"} />
                    <InputNeoGen label={"Email Address"} register={props.register} name={"email"} />
                    <InputNeoGen label={"Phone Number"} register={props.register} name={"phone"} />
                    <InputNeoGen label={"Website"} register={props.register} name={"website"} />

                    <InputNeoGen label={"Address"} register={props.register} name={"address"} />
                    <InputNeoGen label={"City"} register={props.register} name={"city"} />
                    <InputNeoGen label={"State"} register={props.register} name={"state"} />
                    <InputNeoGen label={"Zip"} register={props.register} name={"zip"} />
                </>
            )}
        </div>
    );
}

type AddCompanySectionProps = {
    register: any;
    usersQuery: any;
    data: any;
    accountManager: string;
    setAccountManager: (accountManager: string) => void;
    salesUser: string;
    setSalesUser: (salesUser: string) => void;
    companyCreatedBy: number;
    setCompanyCreatedBy: (companyCreatedBy: number) => void;
    addingCompany: boolean;
    setAddingCompany: (addingCompany: boolean) => void;
    companies: any[];
};
