import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import ButtonNeoGen from "../../../layout/button-neogen";
import TableNeogen from "../../../layout/table-neogen";
import interactionsService from "../../../services/interactions.service";
import usersService from "../../../services/users.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import NewMessage from "../../messages/modals/new-message";
import ShowMessage from "../../messages/modals/show-message";
import CustomerCard from "../customer-card";
import CustomerSummary from "../customer-summary";

export default function SummaryCard({ user }: { user?: ClearERCUser }) {
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageId, setMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState("");
    // const [users, setUsers] = useState<ErcUser[]>([]);
    const [page, setPage] = useState("basic");
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    const messagesQuery = useQuery(["messages"], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    return (
        <>
            <h1 className="text-3xl font-bold tracking-tight text-blue-gray-900">Customer Summary</h1>
            <form className="divide-y-blue-gray-200 mt-6 space-y-8">
                <div className="mt-0 ">
                    <div className="col-span-2 ">
                        <dt className="text-sm font-medium text-gray-500"></dt>
                        <dd className="mt-1 text-sm text-gray-900 ">
                            <CustomerSummary withoutHeader={true} />
                        </dd>
                    </div>
                </div>
                {/* <CustomerCard /> */}
            </form>

            {/* <NewMessage show={show} setShow={setShow} /> */}
            {/* <ShowMessage id={messageId} show={showMessage} setShow={setShowMessage} /> */}
        </>
    );
}
