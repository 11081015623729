import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { getUser } from "../../../jason-proof-of-concept/users/actions/get-user";
import { WizardContainer } from "../../../jason-proof-of-concept/wizard/wizard-container";
import BreadCrumbs from "../../../layout/breadcrumbs";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import Loader2 from "../../utilities/Loader2";

export const UserApplicationPage = () => {
    const authToken = getAuthTokenNoThrow();
    const { userId } = useParams();

    const query = useQuery(["user", { authToken, id: userId }], async () => {
        const user = userId && authToken ? await getUser({ authToken, id: userId }) : undefined;
        return user;
    });

    const user = query.data;
    const name = [user?.firstName, user?.lastName].join(" ").trim() || user?.email;

    return (
        <div>
            <BreadCrumbs
                hideHome
                addMb
                pages={[
                    {
                        name: "Users",
                        href: "/users",
                    },
                    {
                        name: `${name}'s application`,
                        href: `/users/${userId || ""}/application`,
                        current: true,
                    },
                ]}
            />
            {user ? (
                <>
                    <WizardContainer processflowGroupId={7} user={user} />
                </>
            ) : (
                <Loader2 />
            )}
        </div>
    );
};
