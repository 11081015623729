import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { userCompanySchema } from "../domain/user-company";

export const createUserCompany = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: { userId: string; companyId: number; roleId?: number };
}) => {
    const response = await makeRequest({
        method: "post",
        path: "/user-companies",
        authToken,
        data: {
            ercUserId: data.userId,
            companyId: data.companyId,
            roleGroupId: data.roleId,
        },
    });
    const userCompany = parseSchema(userCompanySchema, response.data);
    return userCompany;
};
