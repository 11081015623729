import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../actions/get-users";

const getUsersQueryKey = ({ authToken, filters }: { authToken: string; filters?: any }) => {
    return ["users", { authToken, filters }];
};

export const useUsers = ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const query = useQuery({
        queryKey: getUsersQueryKey({ authToken, filters }),
        queryFn: async () => {
            const users = await getUsers({ authToken, filters });
            return users;
        },
    });
    return query;
};
