import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { TextAreaField } from "../../layout/form/text-area-field";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/useUsers";
import { Call, tagSchema } from "../domain/call";
import { updateCall } from "../actions/update-call";
import Select from "react-tailwindcss-select";
import { getCallTags } from "../../calendar-events/actions/get-call-tags";
import { useState } from "react";
import { StatusType, getCallStatuses } from "../actions/get-call-statuses";
import { SelectField } from "../../layout/form/select-field";

const schema = z.object({
    notes: z.string(),
    // tags: z.array(tagSchema),
    status: z.string(),
});

type Data = z.infer<typeof schema>;

export const EditCallModal = ({
    onClose,
    call,
    onUpdate,
}: {
    onClose: () => any;
    call: Call;
    onUpdate: (call: Call) => any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const form = useForm({
        schema,
        defaultValues: {
            notes: call.notes || undefined,
            status: call.status || undefined,
            // tags: call.tags || [],
        },
    });

    const tagsQuery = useQuery(["company-tags"], async () => {
        const tags = await getCallTags({ authToken });
        return tags;
    });
    const tags = tagsQuery.data || [];

    const [selectedTags, setSelectedTags] = useState([]);

    const handleTagChange = (value: any) => {
        setSelectedTags(value);
    };

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCall = await updateCall({ authToken, id: call.id, data: { ...data } });
            return updatedCall;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedCall = await mutation.mutateAsync(data);
        onUpdate(updatedCall);
    };

    const statusQuery = useQuery(["call-status"], async () => {
        const statuses = await getCallStatuses({ authToken });
        return statuses;
    });
    const callStatuses: StatusType[] = statusQuery.data || [];

    return (
        <ModalDialog size="md" show title="Update call" close={onClose} showCancel={false} showOk={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)}>
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                {/*
                <div className="my-5">
                    <p className="my-3 text-sm">Tags</p>
                    <Select
                        isSearchable={true}
                        isMultiple={true}
                        options={(tags || []).map((tag) => ({ value: tag.tag, label: tag.tag }))}
                        onChange={handleTagChange}
                        value={selectedTags.map((tag) => ({
                            value: tag.id.toString(),
                            label: tags.find((t) => t.id === tag.callTagId)?.tag || "",
                        }))}
                        primaryColor="#6610f2"
                        {...form.getFieldProps("tags")}
                    />
                </div> */}

                <SelectField
                    label="Status"
                    isRequired
                    {...form.getFieldProps("status")}
                    options={Object.values(callStatuses).map((a) => ({
                        value: a.id,
                        label: a.status,
                    }))}
                />

                <ButtonNeoGen block type="submit">
                    {mutation.isLoading ? "Loading..." : "Update"}
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
