import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { NumberInput } from "./number-input";
import { TextInputProps } from "./text-input";

export const NumberField = ({
    name,
    form,
    isRequired,
    label,
    ...other
}: FormFieldProps<number> & GetFieldPropsReturn<any> & TextInputProps) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || null,
    });
    const error = fieldState.error?.message;

    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}>
            <NumberInput {...other} {...field} hasError={!!error} />
        </Field>
    );
};
