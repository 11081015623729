import { useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import InputControlled from "../../../layout/input-controlled";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { usePromiseLazy } from "../../shared/hooks";
import { useParams } from "react-router-dom";
import { setPassword } from "../actions/set-password";
import ButtonNeoGen from "../../../layout/button-neogen";
import authService from "../../../services/auth.service";

export const SetupAccountPage = () => {
    const { verificationToken, userId } = useParams() as {
        verificationToken: string;
        userId: string;
    };
    const [password, setPasswordState] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const isDarkMode = localStorage.getItem("theme") === "dark";

    const createAccountAction = usePromiseLazy(async () => {
        if (password.length < 8) {
            throw new Error("Password must be at least 8 characters long.");
        }
        if (!verificationToken) {
            throw new Error("Verification token not found. Please contact our support team for assistance.");
        }
        if (password !== confirmPassword) {
            throw new Error("Passwords do not match. Please ensure the passwords are the same.");
        }

        const updatedUser = await setPassword({
            verificationToken,
            userId,
            password,
        });

        await authService.login(updatedUser.email, password);

        setUser(updatedUser);
        navigate("/processflow-wizard/" + updatedUser.defaultProcessflowId);
    }, []);

    const handleSubmit = async () => {
        await createAccountAction.execute();
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className="flex-grow max-w-lg mt-20">
                <div className="md:col-span-1  sm:block">
                    <div className="px-4 py-5 bg-white dark:bg-gray-800 space-y-6 sm:p-6 rounded-xl shadow">
                        <h1 style={{ textAlign: "center" }}>Set up your ClearERC account</h1>
                        <p style={{ textAlign: "center" }}>
                            Hello! Please fill out the form below to set up your ClearERC account and to continue with
                            the application process.
                        </p>
                        {createAccountAction.error && (
                            <div className="bg-red-400 p-5 rounded-md">
                                <h2 style={{ fontSize: "20px" }}>Failed to create account</h2>
                                <p>{createAccountAction.error.message}</p>
                            </div>
                        )}
                        <input type="hidden" name="email" value={user.email} />
                        <InputControlled
                            darkBackground={isDarkMode}
                            label={"Set your password"}
                            type="password"
                            value={password}
                            setValue={setPasswordState}
                            name={"password"}
                            noDebounce={true}
                            autoComplete="new-password"
                        />
                        <InputControlled
                            darkBackground={isDarkMode}
                            label={"Confirm your password"}
                            type="password"
                            value={confirmPassword}
                            setValue={setConfirmPassword}
                            name={"confirmPassword"}
                            noDebounce={true}
                        />
                        <ButtonNeoGen onClick={handleSubmit}>Create account</ButtonNeoGen>
                    </div>
                </div>
            </div>
        </div>
    );
};
