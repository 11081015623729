import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export type AgentType = {
    id: string;
    name: string;
    status: "offline" | "available" | "busy";
};

export const getAgents = async ({ authToken }: { authToken: string }) => {
    // const response = await makeRequest({
    //     method: "get",
    //     path: `/agents`,
    //     authToken,
    // });
    return Promise.resolve([
        { id: "1", name: "Agent 1", status: "available" },
        { id: "2", name: "Agent 2", status: "offline" },
        { id: "3", name: "Agent 3", status: "busy" },
        { id: "4", name: "Agent 4", status: "available" },
        { id: "5", name: "Agent 5", status: "offline" },
        { id: "6", name: "Agent 6", status: "busy" },
    ] as AgentType[]);
};
