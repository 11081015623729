/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 9/23/20, 8:36 PM
 *
 */

/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import companyService from "../../../services/company.service";
import { Company } from "../../../typings/api";
import InputControlled from "../../../layout/input-controlled";
import ModalDialog from "../../../layout/modal-dialog";
import SelectNeoGen from "../../../layout/select-neogen";
import Loader2 from "../../utilities/Loader2";
import states from "../../utilities/states.json";

/**
 * @component
 */
function AddCompanyModal(props: AddCompanyModalProps) {
    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyState, setCompanyState] = useState("");
    const [companyCountry, setCompanyCountry] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [ownedBy, setOwnedBy] = useState("");
    const queryCache = useQueryClient();
    const companyQuery = useQuery(["company"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        if (props.isEdit && props.company) {
            // alert("Edit");
            setCompanyName(props.company.name ?? "");
            setCompanyAddress(props.company.address ?? "");
            setCompanyCity(props.company.city ?? "");
            setCompanyState(props.company.state ?? "");
            setCompanyCountry(props.company.country ?? "");
            setCompanyPhone(props.company.phone ?? "");
            setCompanyEmail(props.company.email ?? "");
            setCompanyWebsite(props.company.website ?? "");
            setOwnedBy(props.company.ownedById ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit]);

    return (
        <ModalDialog
            size="md"
            show={props.show}
            close={props.handleClose}
            title="Add Company"
            okAction={() => {
                const data = {
                    name: companyName,
                    address: companyAddress,
                    city: companyCity,
                    state: companyState,
                    country: companyCountry,
                    phone: companyPhone,
                    email: companyEmail,
                    website: companyWebsite,
                    ownedBy: ownedBy,
                };
                // console.log(data);
                if (props.isEdit) {
                    companyService
                        .update(props.company?.id, data)
                        .then((r) => {
                            if (r?.status === 200 || r?.status === 204) {
                                queryCache.invalidateQueries(["companies"]);
                                props.handleClose();
                                Swal.fire({
                                    title: "Success",
                                    text: "Company updated successfully",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }

                            // props.handleClose();
                        })
                        .catch((e) => {
                            alert(e);
                        });
                } else {
                    companyService
                        .create(data)
                        .then(() => {
                            queryCache.invalidateQueries(["companies"]);
                            props.handleClose();
                            Swal.fire({
                                title: "Success",
                                text: "Company added successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            // props.handleClose();
                            // props.loadData();
                        })
                        .catch((e) => {
                            alert(e);
                        });
                }
            }}
        >
            {companyQuery.isLoading ? (
                <Loader2 />
            ) : (
                <div className="grid grid-cols-2 gap-5">
                    <InputControlled
                        label={"Name"}
                        type="text"
                        value={companyName}
                        setValue={setCompanyName}
                        name={"companyName"}
                    />
                    <InputControlled
                        label={"Address"}
                        type="text"
                        value={companyAddress}
                        setValue={setCompanyAddress}
                        name={"companyAddress"}
                    />
                    <InputControlled
                        label={"City"}
                        type="text"
                        value={companyCity}
                        setValue={setCompanyCity}
                        name={"companyCity"}
                    />
                    <SelectNeoGen
                        label={"State"}
                        value={companyState}
                        onChange={(e) => setCompanyState(e as string)}
                        options={states}
                    />

                    <InputControlled
                        label={"Country"}
                        type="text"
                        value={companyCountry}
                        setValue={setCompanyCountry}
                        name={"companyCountry"}
                    />
                    <InputControlled
                        label={"Phone"}
                        type="text"
                        value={companyPhone}
                        setValue={setCompanyPhone}
                        name={"companyPhone"}
                    />
                    <InputControlled
                        label={"Email"}
                        type="text"
                        value={companyEmail}
                        setValue={setCompanyEmail}
                        name={"companyEmail"}
                    />
                    <InputControlled
                        label={"Website"}
                        type="text"
                        value={companyWebsite}
                        setValue={setCompanyWebsite}
                        name={"companyWebsite"}
                    />
                    <SelectNeoGen
                        label={"Owned By"}
                        value={ownedBy}
                        onChange={(e) => {
                            setOwnedBy(String(e));
                        }}
                        options={companyQuery.data ?? []}
                    />
                </div>
            )}
        </ModalDialog>
    );
}

export default AddCompanyModal;

type AddCompanyModalProps = {
    // CompanyService: any,
    handleClose: any;
    // loadData: any,
    show: any;
    isEdit?: boolean;
    company?: Company | null;
};
