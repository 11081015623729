import { MagicLink } from "../magic-links/domain/magic-link";
import { Navigation } from "../typings/api/navigations";
import APIService from "./api.service";

export interface MagicLinkServiceProps {
    total: number;
    hasNextPage: boolean;
    pageItems: MagicLink[];
}

class MagicLinkService extends APIService<MagicLinkServiceProps> {
    constructor() {
        super("magic-links");
    }
    getMagicLinks(offset: number, count: number, searchQuery: string) {
        const whereClause = !searchQuery
            ? {}
            : {
                  or: [
                      {
                          code: {
                              like: `%${searchQuery}%`,
                          },
                      },
                      {
                          name: {
                              like: `%${searchQuery}%`,
                          },
                      },
                      {
                          email: {
                              like: `%${searchQuery}%`,
                          },
                      },
                  ],
              };
        return this.getPaginatedFiltered({ offset, limit: count, where: whereClause });
    }

    resendMagicLink(id: string) {
        return this.postURL(`magic-links/${id}/resend`, {});
    }
}

export default new MagicLinkService();
