import { FetchNextPageOptions } from "@tanstack/react-query";
import { useEffect, useRef, ReactNode } from "react";
import { useIntersection } from "react-use";
import Loader2 from "../sections/utilities/Loader2";

interface Props {
    totalCount?: number;
    currentCount?: number;
    children: ReactNode;
    isLoading?: boolean;
    onRequestToLoadMore: (options?: FetchNextPageOptions) => void;
}

export const PaginatedView = ({
    children,
    isLoading,
    onRequestToLoadMore,
    totalCount = 0,
    currentCount = 0,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: "200px",
        threshold: 0,
    });

    useEffect(() => {
        if (intersection?.isIntersecting && !isLoading) {
            onRequestToLoadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intersection?.isIntersecting, onRequestToLoadMore]);

    return (
        <>
            {totalCount > 0 && currentCount > 0 && (
                <div className="flex justify-end mb-4">
                    <p className="text-sm text-gray-500">
                        Showing <span className="font-medium">{currentCount}</span> of{" "}
                        <span className="font-medium">{totalCount}</span> results
                    </p>
                </div>
            )}
            {children}
            <div ref={ref} />
            {isLoading && <Loader2 />}
        </>
    );
};
