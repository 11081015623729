import { makeRequest } from "../../jason-proof-of-concept/shared/utils";

export type StatusType = {
    id: string;
    status: string;
};

export const getCallStatuses = async ({ authToken }: { authToken: string }) => {
    // const response = await makeRequest({
    //     method: "get",
    //     path: `/call-statuses`,
    //     authToken,
    // });
    return Promise.resolve([
        { id: "1", status: "sale" },
        { id: "2", status: "not interested" },
        { id: "3", status: "bad sound quality" },
        { id: "4", status: "disconnected in the middle of call" },
        { id: "5", status: "disconnected before agent answered" },
        { id: "6", status: "hold music disconnected" },
        { id: "7", status: "dead air disconnected" },
        { id: "8", status: "long hold time disconnected" },
        { id: "9", status: "Sale not verified" },
        { id: "10", status: "Sale not completed" },
        { id: "11", status: "Bad sales rep" },
        { id: "12", status: "Card declined" },
        { id: "13", status: "Interested, No Money" },
        { id: "14", status: "Attempted, Customer Care # given" },
        { id: "15", status: "Interested, But wants to talk to Spouse" },
        { id: "16", status: "not interested DNC remove from list" },
        { id: "17", status: "Not interested angry customer form the start" },
        { id: "18", status: "not qualified" },
        { id: "19", status: "not interested customer hung up" },
        { id: "20", status: "dead air before rep picked up" },
        { id: "21", status: "dead air when rep picked up" },
        { id: "22", status: "close to sale customer backed out at last minute" },
        { id: "23", status: "call back" },
    ]);
};
