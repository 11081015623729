import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type } from "os";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import utmService from "../../services/utm-link.service";
import Loader2 from "../utilities/Loader2";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useState } from "react";
import { UTMLink } from "../../typings/api/utm-links";
import AddEditUtmLink from "./modals/add-edit-utm";
import utmLinkService from "../../services/utm-link.service";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function UTM() {
    const cache = useQueryClient();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [utm, setUtm] = useState<UTMLink | undefined>();
    const [show, setShow] = useState(false);
    const utmQuery = useQuery(["utm"], async () => {
        const response = await utmService.getAll();
        if (response) {
            return response.data;
        }
    });

    function saveNew(utm: Partial<UTMLink>) {
        console.log(utm);
        utmLinkService.create(utm).then((response) => {
            if (response) {
                cache.invalidateQueries(["utm"]);
                SwalNeogenFire({
                    title: "UTM Link Created",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
        });
    }
    function saveEdit(utm: UTMLink) {
        console.log(utm);
        const updatedUtm: UTMLink = {
            id: utm.id,
            name: utm.name,
            source: utm.source ?? "",
            medium: utm.medium ?? "",
            campaign: utm.campaign ?? "",
            term: utm.term ?? "",
            content: utm.content ?? "",
            notes: utm.notes ?? "",
            owner: utm.owner ?? "",
            lastEntityAdded: utm.lastEntityAdded ?? "",
            lastUpdated: new Date(),
            code: utm.code ?? "",
        };
        utmLinkService.update(updatedUtm.id, updatedUtm).then((response) => {
            if (response) {
                cache.invalidateQueries(["utm"]);
                SwalNeogenFire({
                    title: "UTM Link Updated",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
        });
    }

    return (
        <>
            <PageDescription
                description="Affiliate Links are used to track the source of traffic for your ERC leads, You can create an affiliate link for each of   marketing campaigns."
                description2=""
                description3="You will be able to see the number of leads generated by each affiliate link."
                title="Affiliate Links"
                buttons={[
                    {
                        label: "Add UTM Link",
                        onClick: () => {
                            setIsEdit(false);
                            setShow(true);
                        },
                        icon: "fal fa-plus",
                        type: "primary",
                    },
                ]}
            />
            {utmQuery.isLoading ? (
                <Loader2 />
            ) : (
                <div className="p-2">
                    <TableNeogen
                        entries={utmQuery.data ?? []}
                        formatters={[
                            {
                                field: "owner",
                                type: "User",
                            },
                            {
                                field: "lastUpdated",
                                type: "DateTime",
                            },
                            {
                                field: "lastEntityAdded",
                                type: "User",
                            },
                        ]}
                        ignoreFields={[
                            "lastEntityAdded",
                            "notes",
                            "lastUpdated",
                            "source",
                            "medium",
                            "campaign",
                            "term",
                            "content",
                        ]}
                        actions={[
                            {
                                label: "Edit",
                                onClick: (id: string) => {
                                    console.log("Edit");
                                    setIsEdit(true);
                                    setUtm(utmQuery.data?.find((u) => u.id === id));
                                    setShow(true);
                                },
                                icon: "fas fa-edit",
                            },
                            {
                                label: "Landing Page",
                                onClick: (id) => {
                                    // alert(id);
                                    const utm = utmQuery.data?.find((u) => u.id === id);
                                    if (utm) {
                                        navigator.clipboard
                                            .writeText("https://www.clearerc.com/refund/?utm=" + utm.code)
                                            .catch((err) => {
                                                alert(err);
                                            })
                                            .then(() => {
                                                SwalNeogenFire({
                                                    title: "Copied!",
                                                    html:
                                                        "Landing Page Link:<br /><br /> <b class='text-blue-500'>" +
                                                        "https://www.clearerc.com/refund/?utm=" +
                                                        utm.code +
                                                        "</b><br /> <br />  Copied to Clipboard",
                                                    icon: "success",
                                                    showCancelButton: false,
                                                    showConfirmButton: false,
                                                    timer: 3000,
                                                });
                                            });
                                    }
                                },
                                icon: "fad fa-copy",
                                className: "bg-pink-500 text-white",
                            },
                            {
                                label: "iFrame",
                                onClick: (id) => {
                                    // alert(id);
                                    const utm = utmQuery.data?.find((u) => u.id === id);
                                    if (utm) {
                                        navigator.clipboard
                                            .writeText("https://login.clearerc.com/sign-up/9/" + utm.code)
                                            .catch((err) => {
                                                alert(err);
                                            })
                                            .then(() => {
                                                SwalNeogenFire({
                                                    title: "Copied!",
                                                    html:
                                                        "Iframe Link:<br /><br /> <b class='text-blue-500'>" +
                                                        "https://login.clearerc.com/sign-up/9/" +
                                                        utm.code +
                                                        "</b><br /> <br />  Copied to Clipboard",
                                                    icon: "success",
                                                    showCancelButton: false,
                                                    showConfirmButton: false,
                                                    timer: 3000,
                                                });
                                            });
                                    }
                                },
                                icon: "fad fa-copy",

                                className: "bg-orange-500 text-white",
                            },
                            {
                                label: "Logs",
                                onClick: (id) => navigate("/sign-up/" + id),
                                icon: "fad fa-file-chart-column",
                                className: "bg-yellow-500 text-white",
                            },
                            {
                                label: "Delete",
                                className: " bg-red-500 hover:bg-red-800 text-white",

                                onClick: (id) => {
                                    SwalNeogenFire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            utmService.deleteByID(id).then(() => {
                                                cache.invalidateQueries(["defaultUsers"]);
                                                SwalNeogenFire({
                                                    title: "Deleted!",
                                                    text: "Your UTM code has been deleted.",
                                                    icon: "success",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                });
                                            });
                                        }
                                    });
                                },
                                icon: "fas fa-trash",
                            },
                        ]}
                    />
                </div>
            )}

            <AddEditUtmLink
                show={show}
                setShow={setShow}
                isEdit={isEdit}
                utmLink={utm}
                saveNew={saveNew}
                saveEdit={saveEdit}
            />
        </>
    );
}
