import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";
import ButtonNeoGen from "../../layout/button-neogen";
import PrintPre from "../../layout/print-pre";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { createRef, useState, useEffect } from "react";
import gsap from "gsap";
import { DebugError } from "../business-rules/hooks/use-rule-passes";
import useCurrentProgress from "./hooks/use-current-progress";
import { createPartiallyEmittedExpression } from "typescript";
import processflowStageService from "../../services/processflow-stage.service";
import { useRecoilState, useRecoilValue } from "recoil";
//import stageAtom from "../../atoms/stageAtom";
import loadedAtom from "../../atoms/loadedAtom";
import positionAtom, { Position } from "../../atoms/positionAtom";

export type ProcessflowDebugProps = {
    pfDebug: boolean;
    setPfDebug: (pfDebug: boolean) => void;
    // currentStage: number;
    // currentStep: number;
    currentStageName: string;
    currentStepName: string;
    saving: boolean;
    debugErrors?: DebugError[];
    id: number;
    isPublic: boolean;
};

export default function ProcessflowDebug(props: ProcessflowDebugProps) {
    const position = useRecoilValue<Position>(positionAtom);
    const isFetching = useIsFetching();
    const [showSaving, setShowSaving] = useState(false);
    // const [loaded, setLoaded] = useRecoilState<boolean>(loadedAtom);
    const savingDivRef = createRef<HTMLDivElement>();
    const fetchingDivRef = createRef<HTMLDivElement>();
    // const [fetching, setFetching] = useState(false);
    // const {fieldChanged, getProgress,  nextStep, previousStep, getAnswerByFieldName, nextStage, previousStage, saving } = useCurrentProgress(Number(props.id), props.isPublic??false);

    // const stageQuery = useQuery(["processflow-stages", "getAllByGroupId",Number(props.id)], async () => {
    //     const response = await processflowStageService.getAllByGroupId(Number(Number(props.id)));
    //     if (response) {
    //         // alert(processFlowGroupId);
    //         // console.error({ stages: response.data?.length });
    //         return response.data;
    //     }
    // });

    // console.log(currentStage, stageQuery.data);

    useEffect(() => {
        if (isFetching && fetchingDivRef.current) {
            // setFetching(true);
            gsap.to(fetchingDivRef.current, { opacity: 0, duration: 0.5, repeat: 3 });
        } else {
            if (fetchingDivRef.current) {
                setTimeout(() => {
                    // setFetching(false);
                    gsap.to(fetchingDivRef.current, { opacity: 1, duration: 1 });
                }, 1500);
            }
        }
    }, [fetchingDivRef, isFetching]);

    useEffect(() => {
        if (props.saving && savingDivRef.current) {
            setShowSaving(true);
            gsap.to(savingDivRef.current, { opacity: 0, duration: 0.5, repeat: 3 });
        } else {
            if (savingDivRef.current) {
                setTimeout(() => {
                    setShowSaving(false);
                    gsap.to(savingDivRef.current, { opacity: 1, duration: 1 });
                }, 1500);
            }
        }
    }, [props.saving, savingDivRef]);

    // useEffect(() => {
    // const debug = true;
    // console.error({debug, currentStage, currentStep});
    // }, [currentStage, currentStep]);

    return (
        <div className="mb-6">
            {/* <PrintPre>{props}</PrintPre> */}
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-green-600">
                Processflow Debug
                <ButtonNeoGen
                    className="ml-6 dark:bg-green-600 dark:hover:bg-green-700 "
                    size="xs"
                    type="outline-primary"
                    icon="fal fa-power-off"
                    onClick={() => {
                        props.setPfDebug(false);
                    }}
                >
                    Turn Off Processflow Debug
                </ButtonNeoGen>
            </h3>
            {/* <PrintPre>{saving}</PrintPre> */}
            {/* <PrintPre>{isFetching}</PrintPre> */}
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {/* {stats.map((item) => ( */}

                {/* <PrintPre>
                    {[fieldChanged, loaded, currentStage, setCurrentStage, currentStep, setCurrentStep, getAnswerByFieldName, saving]}
                </PrintPre> */}

                <div
                    key={1}
                    className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 dark:text-gray-300 px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                        <div className="absolute rounded-md bg-indigo-500 p-3">
                            <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                            {"Stage " + " (id: " + position.stageId + ")"}
                        </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline">
                        <p className="text-2xl font-semibold text-gray-900 dark:text-green-600">
                            {props.currentStageName.length > 0 ? props.currentStageName : "Unknown Stage"}
                        </p>
                    </dd>
                </div>
                <div
                    key={2}
                    className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 dark:text-gray-300 px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                        <div className="absolute rounded-md bg-indigo-500 p-3">
                            <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                            {"Step " + " (id: " + position.stepId + ")"}
                        </p>
                    </dt>
                    <dd className="ml-16 flex items-baseline">
                        <p className="text-2xl font-semibold text-gray-900   dark:text-green-600">
                            {props.currentStepName.length > 0 ? props.currentStepName : "Unknown Step"}
                        </p>
                    </dd>
                </div>
                <div
                    key={3}
                    className="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 dark:text-gray-300 px-4 pt-5 pb-5 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                        <div className="absolute rounded-md bg-indigo-500 p-3">
                            <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">{"Status"}</p>
                    </dt>
                    <dd className="ml-16 flex items-baseline">
                        {isFetching === 1 && (
                            <div ref={fetchingDivRef} className="text-2xl font-semibold text-red-700">
                                Fetching Updates
                            </div>
                        )}
                        {showSaving && (
                            <div ref={savingDivRef} className=" mx-auto flex-grow text-2xl font-semibold text-blue-500">
                                Saving
                            </div>
                        )}
                        {!isFetching && !showSaving && (
                            <div className=" text-left text-2xl font-semibold text-green-500">{"Ready"}</div>
                        )}
                    </dd>
                </div>

                {(props.debugErrors ?? []).length > 0 && <PrintPre>{props.debugErrors}</PrintPre>}

                {/* ))} */}
            </dl>
        </div>
    );
}
