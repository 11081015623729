import { z } from "zod";
import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";

export const userResponseSchema = z.object({
    id: z.string(),
    company: z.number().nullish(),
    name: z.string().nullish(),
    email: z.string(),
    phone: z.string().optional().nullable(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    utm: z.string().optional().nullable(),
    affiliateUserId: z.string().optional().nullable(),
    defaultProcessflowId: z.number().optional().nullable(),
    companyRoleGroups: z
        .array(
            z.object({
                id: z.number(),
                name: z.string(),
                description: z.string().nullish(),
                owner: z.number().nullish(),
                isPublic: z.number().nullish(),
                isForCompany: z.number().nullish(),
            }),
        )
        .nullish(),
});

const getUsersResponseSchema = z.array(userResponseSchema);

export const getUsers = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/users",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const userDtos = parseSchema(getUsersResponseSchema, response.data);

    const users = parseSchema(
        z.array(userSchema),
        userDtos.map((dto) => ({
            ...dto,
            company: dto.company || undefined,
            name: dto.name || undefined,
        })),
    );

    return users;
};

export const getMyUsers = async ({ authToken }: { authToken: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/my-users",
        authToken,
    });

    const userDtos = parseSchema(getUsersResponseSchema, response.data);

    const users = parseSchema(
        z.array(userSchema),
        userDtos.map((dto) => ({
            ...dto,
            company: dto.company || undefined,
            name: dto.name || undefined,
        })),
    );

    return users;
};
