import { z } from "zod";

export const userSchema = z.object({
    id: z.string(),
    company: z.number().nullish(),
    name: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string(),
    companyRoleGroups: z
        .array(
            z.object({
                id: z.number(),
                name: z.string(),
                description: z.string().nullish(),
                owner: z.number().nullish(),
                isPublic: z.number().nullish(),
                isForCompany: z.number().nullish(),
            }),
        )
        .nullish(),
});

export type User = z.infer<typeof userSchema>;
