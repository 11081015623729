import { useMemo, useState } from "react";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import TableNeogen from "../../layout/table-neogen";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { search as ssSearch } from "ss-search";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/useUsers";
import { getAuthTokenNoThrow } from "../../services/auth-header";

type Agent = {
    firstName: string;
    lastName: string;
    email: string;
    isOnline?: boolean;
    type: string;
};

export const AgentsPage = () => {
    const [search, setSearch] = useState("");
    const [user] = useRecoilState(userAtom);
    const now = new Date();
    const authToken = getAuthTokenNoThrow() || "no-auth";

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    return (
        <div>
            <PageDescription title="Agents" description="See and manage agents.">
                <SearchField search={search} setSearch={setSearch} placeholder="Search agents..." />
            </PageDescription>
            <div className="flex flex-col gap-4">
                <div>
                    {users.length === 0 ? (
                        <div className="text-gray-600 m-4 text-center">No agents</div>
                    ) : (
                        <TableNeogen
                            entries={users.slice(0, 10).map((user) => ({
                                status: "Online",
                                name: [user.firstName, user.lastName].join(" "),
                                email: user.email,
                                type: "Sales",
                            }))}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
