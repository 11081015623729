import ActionsPanel from "../../../layout/actions-panel";
import PageDescription from "../../../layout/page-description";

import {
    AcademicCapIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";
import PushToRemote from "./modals/push-to-remote";
import { useEffect, useState } from "react";

const actions = [
    {
        name: "Push customer to Monday",
        description: "Lets you select a customer and push them to Monday.",
        bg: "bg-teal-100 bordwer border-teal-500",
        action: (id: number) => {
            console.log("Push customer to Monda2y: " + id);
        },
        id: 1,

        icon: "fas fa-calendar fa-arrow",
        iconForeground: "text-teal-700 dark:text-teal-300",
        iconBackground: "bg-teal-100 border border-teal-500 bg-teal-50 dark:bg-teal-900",
    },
    {
        name: "Push customer to Slack",
        description: "Lets you select a customer and push them to Slack.",
        bg: "bg-teal-500",
        id: 2,
        action: (id: number) => {
            console.log("Push customer to Monday: " + id);
        },
        icon: "fa-brands fa-slack",
        iconForeground: "text-indigo-700 dark:text-indigo-300",
        iconBackground: "bg-indigo-100 border border-indigo-500  dark:bg-indigo-900",
    },
    {
        name: "Look up customer in Monday",
        description: "Lets you find a customer in Monday and pull them in or syncronize their data",
        bg: "bg-orange-500",
        id: 3,
        action: (id: number) => {
            console.log("Look up customer in Monday: " + id);
        },
        icon: "fa-brands fa-slack",
        iconForeground: "text-orange-700 dark:text-orange-300",
        iconBackground: "bg-orange-100 border border-orange-500  dark:bg-orange-900",
    },
    {
        name: "Compare deal statuses with Monday",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-red-500",
        id: 4,
        href: "/admin/tools/compare-deals-monday",
        icon: "fas fa-handshake",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Compare affiliate list with Monday",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-blue-200",
        id: 5,
        action: (id: number) => {
            console.log("Look up customer in Monday: " + id);
        },
        icon: "fas fa-circle-nodes",
        iconForeground: "text-blue-300 dark:text-blue-500",
        iconBackground: "bg-blue-100 border border-blue-400  dark:bg-blue-900",
    },
    {
        name: "Compare deal list with Monday",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-pink-500",
        id: 6,
        action: (id: number) => {
            console.log("Look up customer in Monday: " + id);
            300;
        },
        icon: "fas fa-list",
        iconForeground: "text-pink-400 dark:text-pink-300",
        iconBackground: "bg-pink-100 border border-pink-500  dark:bg-pink-900",
    },
    {
        name: "Dispositions",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-green-500",
        id: 7,
        action: (id: number) => {
            console.log("Look up customer in Monday: " + id);
        },
        icon: "fas fa-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Run manual syncronization",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-green-500",
        id: 8,
        action: (id: number) => {
            console.log("Look up customer in Monday: " + id);
        },

        icon: "fas fa-refresh",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
];

// function classNames(...classes: any[]) {
// return classes.filter(Boolean).join(" ");
// }

export default function AdminTools() {
    const [show, setShow] = useState(false);
    const [type, setType] = useState<"slack" | "monday">("slack");
    useEffect(() => {
        actions.forEach(function (part, index, actions) {
            if (actions[index]["id"] === 2) {
                //push to slack

                actions[index]["action"] = () => {
                    // alert("hoi");
                    setType("slack");
                    setShow(true);
                };
            }

            if (actions[index]["id"] === 1) {
                //push to Monday

                actions[index]["action"] = () => {
                    // alert("hoi");
                    setType("monday");
                    setShow(true);
                };
            }
        }, actions as any); // use arr as this
    }, []);
    return (
        <>
            <PageDescription
                title="Admin Tools"
                description="Tools for managing the site and its integration with 3rd party sites"
            />
            <ActionsPanel actions={actions} />
            {/* {show ? "Is Showing" : "Not Showing"} */}
            {/* {type} */}
            <PushToRemote close={() => setShow(false)} show={show} type={type} />
        </>
    );
}
