import { useCallback, useEffect, useMemo, useState } from "react";
import AddCompanyModal from "./modals/add-edit-company-modal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader2 from "../utilities/Loader2";
import { Company } from "../../typings/api/company";
import PageDescription from "../../layout/page-description";
import authService from "../../services/auth.service";
import TableNeogen from "../../layout/table-neogen";
import Swal from "sweetalert2";
import companyService from "../../services/company.service";
import { useNavigate } from "react-router";
import SearchField from "../../layout/search-field";
import { search as ssSearch } from "ss-search";
import { debounce } from "lodash";

function compare(a: any, b: any) {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1;
    }
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
    }
    return 0;
}

function Companies() {
    const [showDelete, setShowDelete] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [search, setSearch] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
    const queryCache = useQueryClient();
    const [canIAccess, setCanIAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        authService.canIAccess("LIST_ALL_COMPANIES").then((r) => {
            setCanIAccess(r);
        });
    }, []);

    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            const tempResponse = response.data.sort(compare).map((c) => {
                const result: Company = {
                    name: c.name,
                    address: c.address,
                    city: c.city,
                    state: c.state,
                    country: c.country,
                    phone: c.phone,
                    email: c.email,
                    website: c.website,
                    ownedById: c.ownedById,
                    id: c.id,
                };
                return result;
            });
            return tempResponse;
        }
    });

    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const filteredCompanies = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(companies, ["name", "email", "phone", "address", "website"], debouncedSearch) as Company[])
                : companies,
        [companies, debouncedSearch],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    function handleClose() {
        setShowDelete(false);
    }

    function handleAddClose() {
        setShowAdd(false);
    }

    return (
        <>
            <PageDescription
                title="Companies"
                description2="This is a list of all companies. You can add, edit and delete companies."
                buttons={[
                    {
                        label: "Add Company",
                        icon: "fas fa-plus",
                        onClick: () => {
                            console.log("Showing Add Company");
                            setCurrentCompany(null);
                            setShowAdd(true);
                        },
                        disabled: !canIAccess,
                    },
                ]}
            >
                <SearchField search={search} setSearch={setSearch} placeholder="Search companies..." />
            </PageDescription>
            <div className=" rounded-xl shadow-md">
                {companiesQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : (
                    <>
                        <TableNeogen
                            entries={filteredCompanies.map((c) => ({
                                id: c.id,
                                name: c.name,
                                email: c.email,
                                phoneNumber: c.phone,
                                website: c.website,
                            }))}
                            actionsAreDropDown
                            actions={[
                                {
                                    label: "View",
                                    icon: "fas fa-pencil",
                                    onClick: (companyId: number) => {
                                        navigate(`/companies/${companyId}`);
                                    },
                                },
                                {
                                    label: "Edit",
                                    icon: "fas fa-pencil",
                                    onClick: (company: number) => {
                                        setCurrentCompany(companiesQuery.data?.find((c) => c.id === company) ?? null);
                                        setShowAdd(true);
                                    },
                                },
                                {
                                    label: "Delete",
                                    onClick: (company: number) => {
                                        setCurrentCompany(companiesQuery.data?.find((c) => c.id === company) ?? null);
                                        Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes, delete it!",
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                        }).then((result) => {
                                            if (result.value) {
                                                companyService.deleteByID(Number(company)).then((r) => {
                                                    queryCache.invalidateQueries(["companies"]);
                                                    Swal.fire({
                                                        title: "Deleted!",
                                                        text: "The company has been deleted.",
                                                        icon: "success",
                                                        showConfirmButton: false,
                                                        timer: 1500,
                                                    });
                                                });
                                            }
                                        });
                                    },
                                    className: "bg-red-600 text-white",
                                    icon: "fas fa-trash",
                                    disabled: (entry: Company) => {
                                        return false;
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            {/* <DeleteCompanyModal show={showDelete} handleClose={handleClose} deleteCompany={deleteCompany}
                deleting={deleting} /> */}
            {showAdd && (
                <AddCompanyModal
                    show={showAdd}
                    handleClose={handleAddClose}
                    company={currentCompany}
                    isEdit={currentCompany != null}
                />
            )}
        </>
    );
}

export default Companies;
