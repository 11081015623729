import { useState } from "react";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import TableNeogen from "../../layout/table-neogen";
import { formatRelative, isPast } from "date-fns";
import { search as ssSearch } from "ss-search";
import { useCalls } from "../hooks/use-calls";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { Call } from "../domain/call";
import { useCalendarEvents } from "../../calendar-events/hooks/use-calendar-events";
import { CalendarEvent } from "../../calendar-events/domain/calendar-event";
import { EditCalendarEventModal } from "../../calendar-events/components/edit-calendar-event-modal";
import { AddCalendarEventModal } from "../../calendar-events/components/add-calendar-event-modal";
import { isFuture } from "date-fns";
import ButtonNeoGen from "../../layout/button-neogen";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/useUsers";
import { NewCallModal } from "../components/new-call-modal";
import { EditCallModal } from "../components/edit-call-modal";

function formatTime(ms: number) {
    if (!ms) return "0s";
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
        return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
    } else if (minutes > 0) {
        return `${minutes}m ${seconds % 60}s`;
    } else {
        return `${seconds}s`;
    }
}

export const MyCallsPage = () => {
    const [eventSearch, setEventSearch] = useState("");
    const [callSearch, setCallSearch] = useState("");
    const now = new Date();
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [calendarEventToEdit, setCalendarEventToEdit] = useState<CalendarEvent | undefined>();
    const [userToCall, setUserToCall] = useState<ClearERCUser | undefined | null>();
    const [callToEdit, setCallToEdit] = useState<Call | undefined | null>();
    const [showAddCalendarEventModal, setShowAddCalendarEventModal] = useState(false);
    const calendarEventsQuery = useCalendarEvents({ authToken });
    const calendarEvents = calendarEventsQuery.data || [];
    const [showEvents, setShowEvents] = useState(true);
    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];

    const callsQuery = useCalls({ authToken });
    const calls = callsQuery.data || [];
    const filteredFutureCalls = (
        eventSearch
            ? ssSearch(
                  calendarEvents.filter((c) => isFuture(c.date)),
                  ["description", "user", "assignee", "title"],
                  eventSearch,
              )
            : calendarEvents.filter((c) => isFuture(c.date))
    ) as CalendarEvent[];

    const filteredPastCalls = (
        callSearch
            ? ssSearch(
                  calls.filter((c) => isPast(c.date)),
                  ["notes"],
                  callSearch,
              )
            : calls.filter((c) => isPast(c.date))
    ) as Call[];

    return (
        <>
            <div>
                <PageDescription
                    title="Calls"
                    description="See your call history and upcoming calls"
                    buttons={[
                        {
                            label: "Add calendar event",
                            icon: "fas fa-calendar",
                            onClick: () => {
                                setShowAddCalendarEventModal(true);
                            },
                        },
                    ]}
                ></PageDescription>
                <div className="flex flex-col gap-2">
                    <div className="flex w-full gap-3 items-center mb-3">
                        <ButtonNeoGen
                            type={!showEvents ? "outline-primary" : "primary"}
                            onClick={() => setShowEvents(true)}
                        >
                            Upcoming calendar events
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            type={showEvents ? "outline-primary" : "primary"}
                            onClick={() => setShowEvents(false)}
                        >
                            Past calls
                        </ButtonNeoGen>
                    </div>
                    {showEvents ? (
                        <>
                            <div>
                                <div className="flex w-full justify-between items-center my-3">
                                    <h2>Upcoming call events</h2>
                                    <SearchField
                                        search={eventSearch}
                                        setSearch={setEventSearch}
                                        placeholder="Search calendar events..."
                                    />
                                </div>

                                {filteredFutureCalls.length === 0 ? (
                                    <div className="text-gray-600 m-4 text-center">No upcoming call events</div>
                                ) : (
                                    <TableNeogen
                                        hideMargin
                                        entries={filteredFutureCalls.map((event) => ({
                                            id: event.id,
                                            date: formatRelative(new Date(event.date), now),
                                            user: `${event.user?.firstName} ${event.user?.lastName} ${
                                                event.user?.name ? `(${event.user?.name})` : ""
                                            }`,
                                            assignee: `${event.assignee?.firstName} ${event.assignee?.lastName} ${
                                                event.assignee?.name ? `(${event.assignee?.name})` : ""
                                            }`,
                                            title: event.title,
                                            description: event.description,
                                            engagementType:
                                                (event.engagementType || "").slice(0, 1).toUpperCase() +
                                                (event.engagementType || "").slice(1).replaceAll("_", " "),
                                        }))}
                                        actionsAreDropDown
                                        actions={[
                                            {
                                                label: "Call now",
                                                icon: "fa fa-phone",
                                                onClick: (e) => {
                                                    setUserToCall(
                                                        filteredFutureCalls.find((f) => f.id === e)
                                                            ?.user as ClearERCUser,
                                                    );
                                                },
                                            },
                                            {
                                                label: "Edit",
                                                icon: "fa fa-pencil-square",
                                                onClick: (calendarEventId: number) => {
                                                    const calendarEvent = calendarEvents.find(
                                                        (c) => c.id === calendarEventId,
                                                    );
                                                    console.log({ calendarEventId, calendarEvent });
                                                    if (calendarEvent) {
                                                        setCalendarEventToEdit(calendarEvent);
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <div>
                            <div className="flex w-full justify-between items-center my-3">
                                <h2>Past calls</h2>
                                <SearchField
                                    search={callSearch}
                                    setSearch={setCallSearch}
                                    placeholder="Search calls..."
                                />
                            </div>
                            {filteredPastCalls.length === 0 ? (
                                <div className="text-gray-600 m-4 text-center">No past calls</div>
                            ) : (
                                <TableNeogen
                                    hideMargin
                                    entries={filteredPastCalls.map((call) => {
                                        const caller = users.find((u) => u.id === call.dialerUserId);
                                        const callerName = caller?.firstName
                                            ? caller?.firstName + " " + caller?.lastName
                                            : "-";
                                        const callee = users.find((u) => u.id === call.receiverUserId);
                                        const calleeName = callee?.firstName
                                            ? callee?.firstName + " " + callee?.lastName
                                            : "-";
                                        return {
                                            id: call.id,
                                            date: formatRelative(new Date(call.date), now),
                                            duration: formatTime(call.duration as number),
                                            callerName: callerName,
                                            callerNumber: call.dialerNumber,
                                            calleeName: calleeName,
                                            calleeNumber: call.receiverNumber,
                                            notes: call.notes,
                                        };
                                    })}
                                    actionsAreDropDown
                                    actions={[
                                        {
                                            label: "Call now",
                                            icon: "fa fa-phone",
                                            onClick: (e) => {
                                                const userId = filteredPastCalls.find(
                                                    (f) => f.id === e,
                                                )?.receiverUserId;
                                                setUserToCall(users.find((u) => u.id === userId));
                                            },
                                        },
                                        {
                                            label: "Edit",
                                            icon: "fa fa-pencil-square",
                                            onClick: (e) => {
                                                const c = filteredPastCalls.find((f) => f.id === e);
                                                setCallToEdit(c);
                                            },
                                        },
                                        {
                                            label: "Schedule new call",
                                            icon: "fa fa-calendar-plus",
                                            onClick: () => {
                                                setShowAddCalendarEventModal(true);
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {showAddCalendarEventModal && (
                <AddCalendarEventModal
                    onClose={() => {
                        calendarEventsQuery.refetch();
                        setShowAddCalendarEventModal(false);
                    }}
                />
            )}
            {userToCall && (
                <NewCallModal
                    user={userToCall}
                    onClose={() => {
                        setUserToCall(undefined);
                        callsQuery.refetch();
                    }}
                />
            )}

            {callToEdit && (
                <EditCallModal
                    call={callToEdit}
                    onClose={() => {
                        setCallToEdit(undefined);
                    }}
                    onUpdate={() => {
                        callsQuery.refetch();
                    }}
                />
            )}

            {calendarEventToEdit && (
                <EditCalendarEventModal
                    calendarEvent={calendarEventToEdit}
                    onClose={() => setCalendarEventToEdit(undefined)}
                    onUpdate={() => {
                        calendarEventsQuery.refetch();
                    }}
                />
            )}
        </>
    );
};
