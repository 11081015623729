import { makeRequest, parseSchema } from "../../shared/utils";
import { userSchema } from "../domain/user";
import { userResponseSchema } from "./get-users";

export const replaceCompanyRoles = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: { companyId: number; roleId: number };
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/users/${id}/replace-company-roles`,
        authToken,
        data: { companyId: data.companyId, roleIds: [data.roleId] },
    });

    const userDto = parseSchema(userResponseSchema, response.data);

    const user = parseSchema(userSchema, {
        ...userDto,
        company: userDto.company || undefined,
        name: userDto.name || undefined,
    });

    return user;
};
