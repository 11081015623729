import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { MagicLink } from "../domain/magic-link";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import usersService from "../../services/users.service";
import { Data, MagicLinkModal } from "./magic-link-modal";
import { editMagicLink, EditMagicLinkData } from "../actions/edit-magic-link";

interface EditMagicLinkProps {
    onClose: () => void;
    defaultValues: EditMagicLinkData;
    onMagicLinkUpdated: (magicLink: MagicLink) => void;
}

export const EditClientMagicLinkModal = ({ onClose, defaultValues, onMagicLinkUpdated }: EditMagicLinkProps) => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [magicLink, setMagicLink] = useState<MagicLink | undefined>();
    const [hasCopied, setHasCopied] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (hasCopied) {
            setTimeout(() => setHasCopied(false), 2000);
        }
    }, [hasCopied]);

    const editMagicLinkMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: EditMagicLinkData }) => {
            return await editMagicLink({ authToken, data });
        },
    });

    const handleSubmit = async (data: Data) => {
        const magicLink = await editMagicLinkMutation.mutateAsync({
            authToken,
            data: {
                id: defaultValues.id,
                options: { affiliateUserId: data.affiliateId || undefined },
                type: defaultValues.type,
                usedAt: defaultValues.usedAt ? new Date(defaultValues.usedAt) : undefined,
                data: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    companyName: data.companyName,
                    email: data.email,
                    phone: data.phone,
                    w2Employees2020: data.w2Employees2020,
                    w2Employees2021: data.w2Employees2021,
                    affiliateId: data.affiliateId,
                    docCollectorId: data.docCollectorId,
                },
            },
        });
        onMagicLinkUpdated(magicLink);
        setMagicLink(magicLink as MagicLink);
    };

    const magicLinkLink = magicLink ? `${window.location.origin}/magic/${magicLink.code}` : undefined;

    const affiliatesQuery = useQuery(["users", "affiliates", "magicLink"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });
    const affiliates = useMemo(() => affiliatesQuery.data || [], [affiliatesQuery.data]);
    const usersQuery = useQuery(["users", "magicLink"], async () => {
        const response = await usersService.getAll();
        return response?.data || [];
    });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    return (
        <MagicLinkModal
            onClose={onClose}
            defaultValues={defaultValues}
            setHasCopied={setHasCopied}
            hasCopied={hasCopied}
            magicLinkLink={magicLinkLink}
            handleSubmit={handleSubmit}
            affiliates={affiliates}
            users={users}
            mutation={editMagicLinkMutation}
            magicLink={magicLink as MagicLink}
            title={magicLink ? "" : "Update Client Magic Link"}
            okText="Update Magic Link"
            type="edit"
        />
    );
};
