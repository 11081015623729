import { useQuery } from "@tanstack/react-query";
import { formatRelative, subDays } from "date-fns";
import { sumBy } from "lodash";
import { HTMLAttributes, ReactNode, StyleHTMLAttributes, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { Currency } from "../billing/components";
import {
    getEstimatedPayout,
    getEstimatedPayoutFromData,
} from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getProcessflowProgresses } from "../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getMyUsers, getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import BreadCrumbs from "../layout/breadcrumbs";
import OptionsDropDown from "../layout/options-dropdown";
import PageDescription from "../layout/page-description";
import { getAuthTokenNoThrow } from "../services/auth-header";

export const Section = ({
    children,
    className,
    style,
}: {
    children: ReactNode;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>["style"];
}) => {
    return (
        <div
            style={style}
            className={`bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow p-5 ${
                className || ""
            }`}
        >
            {children}
        </div>
    );
};

export const Stat = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="text-sm font-medium text-gray-400">{title}</div>
            <div className="text-2xl font-semibold text-gray-500 dark:text-green-500">{value}</div>
        </div>
    );
};

const Table = <R,>({
    headers,
    rows,
}: {
    headers: { key: string; title: ReactNode; align?: "right"; getValue: (row: R) => ReactNode }[];
    rows: R[];
}) => {
    return (
        <table className="min-w-full divide-y dark:divide-gray-900">
            <thead className="dark:bg-gray-800 rounded-t-xl">
                <tr>
                    {headers.map((header) => {
                        return (
                            <th
                                key={header.key}
                                scope="col"
                                style={{ textAlign: header.align }}
                                className="dark:text-gray-500 py-3 px-6 text-left text-sm font-semibold text-gray-900"
                            >
                                {header.title}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map((row) => {
                    return (
                        <tr key={JSON.stringify(row)} style={{ height: 30 }}>
                            {headers.map((header) => {
                                return (
                                    <td className="px-6" key={header.key} style={{ textAlign: header.align }}>
                                        {header.getValue(row)}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const AffiliateBillingPage = () => {
    const now = useMemo(() => new Date(), []);
    const [user] = useRecoilState(userAtom);
    const userId = user.id;
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const navigate = useNavigate();

    const query = useQuery(["affiliate-billing-data", { authToken, userId }], async () => {
        const users = await getMyUsers({ authToken });
        const userIds = users.map((user) => user.id);
        const progresses = await getProcessflowProgresses({ authToken, filters: { userIds, group: 7 } });
        return { users, progresses };
    });

    const users = useMemo(() => query.data?.users || [], [query.data?.users]);
    const processflowProgresses = useMemo(() => query.data?.progresses || [], [query.data?.progresses]);

    const rows = useMemo(
        () =>
            users.map((user) => {
                const processflowProgress = processflowProgresses.find((p) => p.userId === user.id);
                const dealValue = processflowProgress?.data
                    ? getEstimatedPayoutFromData({
                          data: getDataFromProgressData(processflowProgress.data as any),
                      })
                    : 0;
                const estimatedCommission = dealValue * 0.15;
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                return {
                    user,
                    processflowProgress: processflowProgress,
                    progressPercentage,
                    lastActive: subDays(now, 2),
                    dealValue,
                    estimatedCommission,
                };
            }),
        [now, processflowProgresses, users],
    );

    const deaValueInProgress = useMemo(() => sumBy(rows, (row) => row.dealValue || 0), [rows]);
    const expectedCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);
    const outstandingCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <div style={{ display: "flex", flexDirection: "row", gap: 20, flex: 1 }}>
                    <Section
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gridAutoRows: "70px",
                            rowGap: 0,
                            columnGap: 35,
                        }}
                    >
                        <div style={{ gridRow: 1, gridColumn: "1 / 2" }}>
                            <Stat title="Deals value" value={<Currency amount={deaValueInProgress} />} />
                        </div>
                        <div style={{ gridRow: 1, gridColumn: "2 / 3" }}>
                            <Stat title="Expected total commission" value={<Currency amount={expectedCommission} />} />
                        </div>

                        {/* <div style={{ gridRow: 2, gridColumn: "1 / 2" }}>
                            <Stat title="Under CPA review" value={<Currency amount={0} />} />
                        </div>
                        <div style={{ gridRow: 2, gridColumn: "2 / 3" }}>
                            <Stat title="Outstanding commission" value={<Currency amount={outstandingCommission} />} />
                        </div>

                        <div style={{ gridRow: 3, gridColumn: "1 / 2" }}>
                            <Stat title="Under IRS review" value={<Currency amount={0} />} />
                        </div>
                        <div style={{ gridRow: 3, gridColumn: "2 / 3" }}>
                            <Stat title="Paid commission" value={<Currency amount={0} />} />
                        </div> */}
                    </Section>
                </div>
                <Section>
                    <h2>Deals</h2>
                    <Table
                        rows={rows}
                        headers={[
                            {
                                title: "Client",
                                key: "client",
                                getValue: (row) => (
                                    <>{[row.user.firstName, row.user.lastName].join(" ").trim() || user.email}</>
                                ),
                            },
                            {
                                title: "Progress",
                                key: "progress",
                                align: "right",
                                getValue: (row) => <>{row.progressPercentage}%</>,
                            },
                            // {
                            //     title: "Last active",
                            //     key: "lastActive",
                            //     getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            // },
                            {
                                title: "Estimated deal value",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => {
                                    return (
                                        <>
                                            <Currency amount={row.dealValue} />
                                        </>
                                    );
                                },
                            },
                            {
                                title: "Estimated commission",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => (
                                    <>
                                        <Currency amount={row.estimatedCommission} />
                                    </>
                                ),
                            },
                            {
                                title: "",
                                key: "application",
                                align: "right",
                                getValue: (row) => (
                                    <OptionsDropDown
                                        options={[
                                            // {
                                            //     label: "View deal",
                                            //     action: () => {
                                            //         navigate(`/my-deals/${row.user.id}`);
                                            //     },
                                            //     icon: "fa fa-pencil",
                                            // },
                                            {
                                                label: "View application",
                                                action: () => {
                                                    navigate(`/users/${row.user.id}/application`);
                                                },
                                                icon: "fa fa-user",
                                            },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                </Section>
                {/* <Section>
                    <h2>Under CPA review</h2>
                    <Table
                        rows={rows}
                        headers={[
                            {
                                title: "Client",
                                key: "client",
                                getValue: (row) => (
                                    <>{[row.user.firstName, row.user.lastName].join(" ").trim() || user.email}</>
                                ),
                            },
                            {
                                title: "Filed",
                                key: "filed",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Last active",
                                key: "lastActive",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Estimated deal value",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => {
                                    return (
                                        <>
                                            <Currency amount={row.dealValue} />
                                        </>
                                    );
                                },
                            },
                            {
                                title: "Estimated commission",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => (
                                    <>
                                        <Currency amount={row.estimatedCommission} />
                                    </>
                                ),
                            },
                            {
                                title: "",
                                key: "application",
                                align: "right",
                                getValue: (row) => (
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "View deal",
                                                action: () => {
                                                    navigate(`/my-deals/${row.user.id}`);
                                                },
                                                icon: "fa fa-pencil",
                                            },
                                            {
                                                label: "View application",
                                                action: () => {
                                                    navigate(`/users/${row.user.id}/application`);
                                                },
                                                icon: "fa fa-user",
                                            },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                </Section>
                <Section>
                    <h2>Under IRS review</h2>
                    <Table
                        rows={rows}
                        headers={[
                            {
                                title: "Client",
                                key: "client",
                                getValue: (row) => (
                                    <>{[row.user.firstName, row.user.lastName].join(" ").trim() || user.email}</>
                                ),
                            },
                            {
                                title: "Filed",
                                key: "filed",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Last active",
                                key: "lastActive",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Estimated deal value",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => {
                                    return (
                                        <>
                                            <Currency amount={row.dealValue} />
                                        </>
                                    );
                                },
                            },
                            {
                                title: "Estimated commission",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => (
                                    <>
                                        <Currency amount={row.estimatedCommission} />
                                    </>
                                ),
                            },
                            {
                                title: "",
                                key: "application",
                                align: "right",
                                getValue: (row) => (
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "View deal",
                                                action: () => {
                                                    navigate(`/my-deals/${row.user.id}`);
                                                },
                                                icon: "fa fa-pencil",
                                            },
                                            {
                                                label: "View application",
                                                action: () => {
                                                    navigate(`/users/${row.user.id}/application`);
                                                },
                                                icon: "fa fa-user",
                                            },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                </Section>
                <Section>
                    <h2>Completed deals</h2>
                    <Table
                        rows={rows}
                        headers={[
                            {
                                title: "Client",
                                key: "client",
                                getValue: (row) => (
                                    <>{[row.user.firstName, row.user.lastName].join(" ").trim() || user.email}</>
                                ),
                            },
                            {
                                title: "Completed",
                                key: "completed",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Last active",
                                key: "lastActive",
                                getValue: (row) => <>{formatRelative(row.lastActive, now)}</>,
                            },
                            {
                                title: "Deal value",
                                key: "dealValue",
                                align: "right",
                                getValue: (row) => {
                                    return (
                                        <>
                                            <Currency amount={row.dealValue} />
                                        </>
                                    );
                                },
                            },
                            {
                                title: "Commission paid",
                                key: "commissionPaid",
                                align: "right",
                                getValue: (row) => (
                                    <>
                                        <Currency amount={row.estimatedCommission} /> of{" "}
                                        <Currency amount={row.estimatedCommission} />
                                    </>
                                ),
                            },
                            {
                                title: "Outstanding commission",
                                key: "outstandingCommission",
                                align: "right",
                                getValue: (row) => (
                                    <>
                                        <Currency amount={row.estimatedCommission} />
                                    </>
                                ),
                            },
                            {
                                title: "",
                                key: "application",
                                align: "right",
                                getValue: (row) => (
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "View deal",
                                                action: () => {
                                                    navigate(`/my-deals/${row.user.id}`);
                                                },
                                                icon: "fa fa-pencil",
                                            },
                                            {
                                                label: "View application",
                                                action: () => {
                                                    navigate(`/users/${row.user.id}/application`);
                                                },
                                                icon: "fa fa-user",
                                            },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                </Section> */}
            </div>
        </div>
    );
};
