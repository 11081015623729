import { makeRequest } from "../../shared/utils";
import { getUser } from "./get-user";

export const updateUser = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: { email?: string; firstName?: string; lastName?: string; phone?: string };
}) => {
    await makeRequest({
        method: "patch",
        path: `/users/${id}`,
        authToken,
        data,
    });

    const updatedUser = await getUser({ authToken, id });

    return updatedUser;
};
